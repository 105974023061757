import axios from "../plugin/http";

const homePage = {
  /**
   * 搜索
   *
   */
  search(title) {
    return axios.get(`/searchHello/${title}/1`);
  },
  /**
   * 高级搜索
   *
   */
  seniorSearch(type, data, app, keyword) {
    return axios.get(`/searchGao/${type}/${data}/${app}/${keyword}/1`);
  },
  /**
   * 应用场景
   * @param {Object} params
   */
  appscene(params) {
    return axios.get("/appscene", params);
  },

  /**
   * 数据领域
   * @param {Object} params
   */
  dataDomain(params) {
    return axios.get("/datarealm", params);
  },
  dataMenu() {
    return axios.get("/datamenu");
  }
};
export default homePage;
