import Vue from "vue";
import Vuex from "vuex";
import api from "@/api";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    accountInfo: localStorage.getItem("accountInfo")
      ? JSON.parse(localStorage.getItem("accountInfo"))
      : {}
  },
  mutations: {
    setAccountInfo(state, data) {
      state.accountInfo = Object.assign({}, state.accountInfo, data);
      localStorage.setItem("accountInfo", JSON.stringify(data));
    },
    clearAccountInfo(state) {
      state.accountInfo = {};
      localStorage.removeItem("accountInfo");
    }
  },
  actions: {
    getAccountInfo({ commit }, params) {
      let accountInfo = {};
      if (params.code) {
        accountInfo.code = params.code;
      }
      if (params.token) {
        accountInfo.token = params.token;
      }
      commit("setAccountInfo", accountInfo);
      return api.account.getAccountInfo(params).then(data => {
        if (data.status == 0) {
          commit("setAccountInfo", {
            name: data.name,
            token: data.token
          });
        }
      });
    },
    clearAccountInfo({ commit }) {
      commit("clearAccountInfo");
    }
  },
  modules: {}
});
