import axios from "../plugin/http";

const typicalApplication = {
  /**
   * 典型应用列表
   * @param {Object} params
   * @param {String} params.title
   * @param {Number} params.pagenum
   */
  list(params) {
    return axios.get("/applist", { params });
  },
  detailPage(params) {
    return axios.get("/appone", { params });
  },
  detail(params) {
    return axios.get("/applist", params);
  },
  /**
   * 典型应用左侧菜单
   */
  nav() {
    return axios.get("/appmenu");
  }
};

export default typicalApplication;
