import axios from "../plugin/http";

const account = {
  /**
   * 获取token
   * @param {String} params.token
   * @param {String} params.code   code|token二选一
   */
  getAccountInfo(params) {
    return axios.get("/sso/oauth2", { params });
  },
  /**
   * 退出登录
   */
  getLogout() {
    return axios.get("/sso/logout");
  }
};

export default account;
