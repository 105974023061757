import axios from "axios";
import { Message } from "element-ui";
// import account from "../api/account";
axios.defaults.headers["Content-Type"] = "application/json";
// axios.defaults.headers["Accept"] = "*/*";
axios.defaults.baseURL =
  process.env.NODE_ENV === "production" ? "/portal/rs/aws" : "/api";

axios.interceptors.request.use(
  config => {
    config.headers.Authorization = `Basic cHNjOjEyMzQ1Ng==`;
    let accountInfo = localStorage.getItem("accountInfo")
      ? JSON.parse(localStorage.getItem("accountInfo"))
      : {};
    if (!config.params) {
      config.params = {};
    }
    if (accountInfo.token) {
      config.params["token"] = accountInfo.token;
    }
    if (accountInfo.code) {
      config.params["code"] = accountInfo.code;
    }
    console.log(config);
    return config;
  },
  error => {
    console.log(error);
    return Promise.error(error);
  }
);

axios.interceptors.response.use(
  response => {
    console.log(response);
    debugger;
    if (response.status === 200) {
      if (response.data.status && response.data.status === 302) {
        window.location.href = response.data.redict_url;
      } else {
        return response.data;
      }
    } else {
      return;
    }
  },
  error => {
    console.log(error);
    switch (error.response.status) {
      case 404:
        Message({
          type: "error",
          message: error.response.data.message || "请求地址不存在！",
          showClose: true
        });
        break;
      case 500:
        Message({
          type: "error",
          message: error.response.data.message || "网络出现错误，请稍后重试！",
          showClose: true
        });
        break;
      default:
        Message({
          type: "error",
          message:
            error.response.data.message || "请求出现未知错误，请稍后重试！",
          showClose: true
        });
    }
  }
);

export default axios;
