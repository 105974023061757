import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home/index.vue") // 首页
  },
  {
    path: "/data-source",
    name: "DataSource",
    component: () => import("../views/DataSource") //数据资源
  },
  {
    path: "/data-source/detail/:id",
    name: "DataSourceDetail",
    component: () => import("../views/DataSource/detail") // 数据资源详情
  },
  {
    path: "/applied-learning",
    name: "AppliedLearning",
    component: () => import("../views/AppliedLearning") //应用学习
  },
  {
    path: "/typical-application",
    name: "TypicalApplication",
    component: () => import("../views/TypicalApplication") // 应用场景
  },
  {
    path: "/typical-application/detail/:id",
    name: "TypicalApplicationDetail",
    component: () => import("../views/TypicalApplication/detail") // 应用场景详情
  },
  {
    path: "/data-analysis",
    name: "DataAnalysis",
    component: () => import("../views/DataAnalysis") //数据分析
    // redirect: { name: "404", query: { nav: "data-analysis" } }
  },
  {
    path: "/interaction",
    name: "interaction",
    // component: () => import("../views/Interaction")//互动交流
    redirect: { name: "404", query: { nav: "interaction" } }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../views/Rooms/404")
  }
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});

export default router;
