import homePage from "./homePage";
import dataSource from "./dataSource";
import account from "./account";
import typicalApplication from "./typicalApplication";

export default {
  homePage,
  dataSource,
  account,
  typicalApplication
};
