import axios from "../plugin/http";

const dataSource = {
  /**
   * 数据资源页菜单数据
   */
  menu() {
    return axios.get("/datamenu");
  },
  /**
   * 数据资源页列表
   * @param {Object} params
   * @param {String} params.pagenum
   */
  list(params) {
    return axios.get(`/searchGao`, { params });
  },
  /**
   * 数据详情页
   * @param {Object} params
   * @param {String} params.boid  数据id
   */
  detail(params) {
    return axios.get(`/dataminute/${params.boid}`);
  },
  /**
   * 附件下载接口
   * @param {Object} params
   * @param {String} params.id  数据id
   */
  download(params) {
    return axios.get(`/test/fjload/${params.id}`);
  },
  /**
   * 所有目录导出
   */
  export() {
    return axios.get("/daochu");
  },
  /**
   * 图表数据
   */
  chart() {
    return axios.get("/datatypesize");
  }
};
export default dataSource;
