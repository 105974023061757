<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    getAccountInfo() {
      let currentUrl = window.location.href;
      if (currentUrl.includes("code") || currentUrl.includes("token")) {
        let queryName =
          (currentUrl.includes("code") && "code") ||
          (currentUrl.includes("token") && "token");
        let pattern = new RegExp(queryName + "=(\\w+)");
        let matches = window.location.href.match(pattern);
        let newUrl = window.location.href.replace(pattern, "");
        this.$store
          .dispatch("getAccountInfo", {
            [`${queryName}`]: matches[1]
          })
          .then(() => {
            window.location.href = newUrl; // 为了炎黄系统里url中code的清除
            // history.replaceState(null, document.title, newUrl);
          });
      } else {
        let accountInfo = localStorage.getItem("accountInfo");
        accountInfo = accountInfo ? JSON.parse(accountInfo) : {};
        this.$store.dispatch("getAccountInfo", {
          token: accountInfo.token
        });
      }
    }
  },
  mounted() {
    this.getAccountInfo();
  }
};
</script>
